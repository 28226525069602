<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="filteredNavMenuItems" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    return {
      navMenuItems,
    }
  },
  computed: {
    userRole() {
      return this.$store.state.usersConfig.currentUser ? this.$store.state.usersConfig.currentUser.roles[0].name : null;
    },
    filteredNavMenuItems() {
      const filteredMenuItems = JSON.parse(JSON.stringify(navMenuItems));

      // Define the mapping of routes to roles that should have access to them
      const routeToRoles = {
        'home': ['SUPERADMIN', 'HEAD_OF_ORGANIZATION', 'HEAD_OF_DEPARTMENT', 'PROJECT_MANAGER', 'EMPLOYEE'],
        'users': ['SUPERADMIN', 'HEAD_OF_DEPARTMENT'],
        'organizations': ['SUPERADMIN'],
        'departaments': ['SUPERADMIN', 'HEAD_OF_ORGANIZATION'],
        'projects': ['SUPERADMIN', 'HEAD_OF_ORGANIZATION', 'HEAD_OF_DEPARTMENT'],
        'tasks': ['SUPERADMIN', 'HEAD_OF_ORGANIZATION', 'HEAD_OF_DEPARTMENT', 'PROJECT_MANAGER'],
        'activities': ['SUPERADMIN', 'HEAD_OF_ORGANIZATION', 'HEAD_OF_DEPARTMENT', 'PROJECT_MANAGER', 'EMPLOYEE'],
        'logs': ['SUPERADMIN']
      };

      // Iterate through the menuItems and remove any child route that the user's role doesn't have access to
      for (const menuItem of filteredMenuItems) {
        if (menuItem.children) {
          menuItem.children = menuItem.children.filter((child) => {
            const allowedRoles = routeToRoles[child.route];
            return !allowedRoles || allowedRoles.includes(this.userRole);
          });
        }
      }

      // Remove parent menu items that have no child routes left
      return filteredMenuItems.filter((menuItem) => menuItem.children.length > 0);
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
